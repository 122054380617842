import React from 'react';
import documentToReactComponents from '@contentful/rich-text-react-renderer';
import "./Artwork.css"

const OneArtwork = ({oneArtwork}) => {
  const artwork = oneArtwork.fields
  return ( 
    <div className='artwork'>
      <div className='artwork-image'>
        {/* <div className='artwork-image' style={{"background-image": `url(${artwork.file.url})`}}> */}
        <img className='artwork-image' width='250' src={"https:" + artwork.file.url} alt={artwork.description}/>
      </div>
      <p className='artwork-title' > {artwork.title} </p>
      {/* <p className='artwork-description' > {artwork.description} </p> */}
    </div>  
  )
}

const options = {
  renderText: (text) => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

export default OneArtwork;