import React from 'react';
import Flash from './Flash';
import './Flash.css';

const OneFlash = ({oneFlash}) => {
  const image = oneFlash.fields.file.url

  return (
    <div className="flash">
     <img className='flash-image' width='300' src={'https:' + image}/>
    </div>
  )
}

export default OneFlash;