import React, { useState, useEffect} from 'react';
import About from './About/About';
import Flash from './Flash/Flash';
import Artwork from './Artwork/Artwork';
import Bookings from './Bookings/Bookings';
import Faq from './Faq/Faq';
import PageNotFound from './PageNotFound/PageNotFound';
import Success from './Success/Success';
import banner from "./Navbar/banner.png";
import Navbar from './Navbar/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// App.js is responsible for the html displayed in the browser
// App component represents the view we see in the browser
const App = () => {  
  const [about, setAbout] = useState([])
  const [flash, setFlash] = useState([])
  const [artwork, setArtwork] = useState([])
  const [bookings, setBookings] = useState([])
  const [faq, setFaq] = useState([])

  const contentfulEndpoint = `https://cdn.contentful.com/spaces/87bumm8741hb/environments/master/entries?access_token=${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}&metadata.tags.sys.id[all]=`

  function getBookings() {
    return fetch(contentfulEndpoint + 'bookings')
    .then(response => {
      return response.json()
    })
    .then(data => setBookings(data))
  }

  function getFaq() {
    return fetch(contentfulEndpoint + 'faq')
    .then(response => {
      return response.json()
    })
    .then(data => setFaq(data))
  }

  function getArtwork() {
    return fetch(contentfulEndpoint + 'artwork')
    .then(response => {
      return response.json()
    })
    .then(data => setArtwork(data))
  }

  function getAbout() {
    return fetch(contentfulEndpoint + 'about')
    .then(response => {
  
      return response.json()
    })
    .then(data => setAbout(data))
  }

  function getFlash() {
    return fetch(contentfulEndpoint + 'flash')
    .then(response => {
      return response.json()
    })
    .then(data => setFlash(data))
  }

  useEffect(() => {
    getAbout();
    getFlash();
    getArtwork();
    getBookings();
    getFaq();
  }, []);

  return (
  <main>
    <div class="container">
    <img src={banner} height={80} style={{ marginTop: '10px'}} className="banner"></img>
    </div>
    <BrowserRouter>
    <Navbar />
      <Routes>
          <Route path="/about" element={<About about={about}/>} />
          <Route path="/flash" element={<Flash flash={flash}/>}/>
          <Route path="/artwork" element={<Artwork artwork={artwork}/>}/>
          <Route path="/bookings" element={<Bookings bookings={bookings}/>}/>
          <Route path="/faq" element={<Faq faq={faq}/>}/>
          <Route path="/" element={<About about={about}/>} />
          <Route path="/success" element={<Success/>}/>
          <Route path="/*" element={<PageNotFound/>}/>
          {/* <Route path="" element={}/>
          <Route index element={}/> */}
      </Routes>
    </BrowserRouter>
  </main>
  );
};
// can route to a landing page component
// can route to a custom 404 page

export default App;
