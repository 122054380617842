import React from "react";

// const OneAbout = ({ oneAbout, image }) => {
//   const bio = oneAbout.fields.content;
//   const pic = image[0].fields.file.url;

//   return (
//     <div className="one-about">
//       <img
//         width="280"
//         src={
//           pic
//         }
//       />
//       <p>{bio}</p>
//     </div>
//   );
// };

// export default OneAbout;

const OneAbout = ({ oneAbout, image }) => {
  const bio = oneAbout.fields.content;
  const pic = image[0].fields.file.url;

  return (
    <div className="center-container">
      <div className="one-about">
        <div className="content-container">
          <img
            width="280"
            src={pic}
            alt="Profile"
          />
          <p>{bio}</p>
          <a href={"https://form.jotform.com/242394910190051"}>Sign up for my newsletter!</a>
        </div>
      </div>
    </div>
  );
};

export default OneAbout;
