import React from 'react';
import OneFaq from './OneFaq';

const Faq = ({faq}) => {
  const faqData = faq.items
  return (
    <div className="container">
      <p className='faq'>
        {faqData && faqData.map((oneFaq) => (
          <OneFaq oneFaq={oneFaq}/>
        ))}
      </p>
    </div>
  );
}

export default Faq;