import "./About.css";
import React, { useEffect } from "react";
import OneAbout from "./OneAbout";

// functional component - accepts an input of properties and returns
// html that describes the UI
const About = ({ about }) => {

  // const bio = about.items[0].fields.content;
  // const aboutImage = about.items[0].fields.file.url;
  const aboutData = about.items;
  const aboutImage = about.includes.Asset
  return (
    // this is JSX
    // JSX translates into React.createElement which is pulled form the react library
    // which is why we have to import react when using jsx
    // pure JS would be
    // return React.createElement(
    //  'div',
    // {id: 'hello', className: 'class'},
    // React.createElement('h1', null, 'Hello')
    //  )
    // }
    <div>
      <div className="about-container">
        {aboutData &&
          aboutData.map((oneAbout) => <OneAbout oneAbout={oneAbout} image={aboutImage} />)}
      </div>
    </div>
  );
};
// OneAbout is name of component, oneAbout is prop = {oneAbout} is the value of the prop
export default About;
// merge this oneAbout into just an About 