import React from 'react';
import "./Faq.css"

const OneFaq = ({oneFaq}) => {
  const content = oneFaq.fields

  return (
    <div className='one-faq'>
      <h2>{content.question}</h2>
      <p>{content.answer}</p>
     <div>
     </div>
   </div>
  )
}

export default OneFaq;