import React from 'react';
import OneFlash from './OneFlash';


const Flash = ({flash}) => {
  const flashData = flash.includes.Asset
  return (
    <div className="container">
      <div className='flash-container'>
        {flashData && flashData.map((oneFlash) => (
          <OneFlash oneFlash={oneFlash}/>
        ))}
      </div>
    </div>
  );
}

export default Flash;