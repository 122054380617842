import React from 'react';
import Booking from './Booking';
import "./Bookings.css";

// shortcut ({bookings}) => props
const Bookings = (props) => {
  const {bookings} = {...props}
  // const bookings = props.bookings
  const bookingsData = bookings.items
  
  return (
    <div>
      <div className='booking-container'>
        {bookingsData && bookingsData.map((booking) => (
          <Booking booking={booking}/>
        ))}
      </div>
    </div>
  );
}
// left is name of the value, right is value of the value booking={booking}
export default Bookings;