import React, { useState } from 'react';
import "./CustomForm.css";
import Success from '../Success/Success';

function CustomForm() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');  
  const [email, setEmail] = useState('');
  const [pronouns, setPronouns] = useState('');
  const [description, setDescription] = useState('');
  const [budget, setBudget] = useState('');
  const [placement, setPlacement] = useState('');

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const customRequest = { firstName, lastName, email, pronouns, description, budget, placement };
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "custom-form", ...customRequest })
    })
        .then(() => {
            window.location.href = "/success";
        })
        .catch((error) => alert(error));
  };

  return (
    <div className="form">
    <form onSubmit={handleSubmit}>
      <div className="form-field">
      <label>First name</label>
      <br/>
        <input
          type="text" 
          name="firstName"
          required
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      <br></br>
      </div>

      <div className="form-field">
      <label>Last name</label>
      <br/>
        <input
          type="text" 
          name="lastName"
          required
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <br></br>
      </div>

      <div className="form-field">
      <label>Email Address</label>
      <br/>
        <input
          type="text" 
          name="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br></br>
      </div>

      <div className="form-field">
      <label>What are your pronouns?</label>
      <br/>
        <input
          type="text" 
          name="pronouns"
          value={pronouns}
          onChange={(e) => setPronouns(e.target.value)}
        />
      <br></br>
      </div>

      <div className="form-field">
      <label>Describe what you're looking for. Please be as specific as possible.</label>
      <br/>
        <textarea
        name="description"
        required
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        ></textarea>
        <br></br>
      </div>

      <div className="form-field">
      <label>Do you have a budget for this design? (Please see the pricing section on my about page for guidance.)</label>
      <br/>
        <input
          type="text"
          name="budget"
          value={budget}
          onChange={(e) => setBudget(e.target.value)} 
        />
        <br></br>
      </div>

      <div className="form-field">
      <label>Desired placement? (It's okay if you don't know yet) </label>
      <br/>
        <input
          type="text"
          name="placement"
          value={placement}
          onChange={(e) => setPlacement(e.target.value)} 
        />
        <br></br>
      </div>

      <div className="form-field">
      <button type="submit">Submit</button>
      </div>
      
    </form>
    </div>
  )
}

export default CustomForm;