import React from 'react';
import CustomForm from './CustomForm';
import custom from './custom.png';
import flash from './flash.png';
import './Bookings.css'; // Make sure this path points to your CSS file

const Booking = ({ booking }) => {
  const content = booking.fields.content;
  const link = booking.fields.link;

  return (
    <div className="booking">
      <div className="booking-content">
        <img src={flash} height={120} width={320} className="image-with-border" alt="Flash" />
          <p>{content}</p>
        <div className="link">
          {link && <a href={link} className="button">Book here</a>}
        </div>
      </div>
      <div className="custom-image">
        <img src={custom} height={100} width={300} alt="Custom" />
      </div>
        <div className="custom-form-container">
          <CustomForm />
      </div>
    </div>
  );
}

export default Booking;
