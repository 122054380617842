import React from 'react';
import OneArtwork from './OneArtwork'

const Artwork = ({artwork}) => {
  const artworkData = artwork.includes.Asset
  return (
    <div className="container">
      
      <div className='artwork-container'>
        {artworkData && artworkData.map((oneArtwork) => (
          <OneArtwork oneArtwork={oneArtwork} />
        ))}
      </div>
    </div>
  );
}

export default Artwork;