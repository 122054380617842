import React from "react";
import {Link, useMatch, useResolvedPath} from "react-router-dom";
import House1 from "./House1.png"
import House2 from "./House2.png"
import House3 from "./House3.png"
import House4 from "./House4.png"
import House5 from "./House5.png"
import "./Navbar.css";

export default function Navbar() {
  return <nav className="nav">
    <Link to="/" className="site-name"></Link>
    <ul>
      <div className="head-text">
        <div className="head-image">
          <CustomLink to="/about"><img src={House1} height={85} width={85}></img>
            <div className="text-on-image-1">
                About
            </div>
          </CustomLink>
        </div>
      </div>

    <div className="head-text">
      <div className="head-image">
        <CustomLink to="/bookings"><img src={House4} height={82} width={80}></img>
          <div className="text-on-image-2">
              Booking
          </div>
        </CustomLink>
        </div>
      </div>

    <div className="head-text">
      <div className="head-image">
        <CustomLink to="/faq"><img src={House3} height={80} width={80}></img>
          <div className="text-on-image-3">  
            FAQ
          </div>
        </CustomLink>
        </div>
      </div>

    <div className="head-text">
      <div className="head-image">
        <CustomLink to="/flash"><img src={House5} height={80} width={80}></img>
          <div className="text-on-image-4">  
          Flash
          </div>
        </CustomLink>
        </div>
      </div>


    <div className="head-text">
      <div className="head-image">
        <CustomLink to="/artwork"><img src={House2} height={80} width={80}></img>
          <div className="text-on-image-5"> 
            Artwork
          </div>
        </CustomLink>
      </div>
    </div>
    </ul>
  </nav>
}

function CustomLink({to, children,...props}) {
  const resolvedPath = useResolvedPath(to)
// make sure path is full absolute path even if it starts out relative
const isActive = useMatch({path: resolvedPath.pathname, end: true})
  return (
    <li className={isActive ? "active" : ""}>
    <Link to={to}{...props}> 
    {children}
    </Link>
    </li>
  )
}