import "./Success.css";
import React from "react";

const Success = () => {
  return (
    <div>
      <h1>Your submission has been received!</h1>
      <a href="/bookings">Return to site </a>
  </div>
  );
};

export default Success;