import "./PageNotFound.css";
import React from "react";

const PageNotFound = () => {
  return (
    <div>
      <h1>404 Error</h1>
      <p>Oops, this page doesn't exist! Please try again.</p>
  </div>
  );
};


export default PageNotFound;